import React from "react"
import PropTypes from "prop-types"

const Container = ({ children }) => {
  return (
    <section className=" max-w-screen-md md:pl-10 pl-7 lg:ml-64  pr-7 mt-12 ">
      {children}
    </section>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
