import React from "react"

const BackToHomeIcon = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.60005 1.15482L1.1687 6.74888"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M7.26274 10L1.1687 4.09634"
        stroke="white"
        strokeWidth="1.72687"
      />
    </svg>
  )
}

export default BackToHomeIcon
