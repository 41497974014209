import React from "react"
import PropTypes from "prop-types"
import PageHeaderTitle from "./title"
import ctl from "@netlify/classnames-template-literals"

const PageHeader = ({ topText = "", bottomText = "", title = "" }) => {
  const style = ctl(`
  font-serif 
  md:text-8xl 
  text-5xl
  bg-white 
  text-black 
  inline-block
  md:pt-8
  pt-4
  `)
  const topTextStyle = ctl(`
  md:pl-36
  pl-12
  md:pr-8
  pr-5
  z-10
  absolute
  top-0
  left-0
  `)

  const bottomTextStyle = ctl(`
  md:ml-28
  ml-7
  md:pl-8
  pl-5
  md:pr-12
  pr-5
  md:-mt-8
  -mt-4
  -pb-8
  leading-none
  absolute
  bottom-0
  left-0
  `)

  const heading = (
    <div className="md:h-[236px] h-[120px] relative">
      <h1 className={style + " " + topTextStyle}>{topText}</h1>
      <br />
      <h1 className={style + " " + bottomTextStyle}>{bottomText}</h1>
    </div>
  )
  return (
    <header className="bg-black    pt-28 relative overflow-hidden  text-white ">
      <>
        <PageHeaderTitle title={title} />
        {topText && bottomText ? heading : null}
      </>
    </header>
  )
}

PageHeader.propTypes = {
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
}

export default PageHeader
