import React from "react"
import Layout from "../components/layout"
import AboutOrganizerDescription from "../components/pages/about-organizer/description"
import MoreAboutFourthCanvas from "../components/pages/about-organizer/more-button"
import PageHeader from "../components/utils/page-header"

const Organizer = ({ location }) => {
  return (
    <Layout title="About Organizer" url={location.pathname}>
      <PageHeader
        topText="FourthCanvas."
        bottomText="For the few."
        title="About Organizer"
      />
      <AboutOrganizerDescription />
      <MoreAboutFourthCanvas />
    </Layout>
  )
}

export default Organizer
