import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BackToHomeIcon from "../../icons/back-to-home"

const PageHeaderTitle = ({ title = "" }) => {
  const titleBar = (
    <>
      <p className="uppercase md:text-xl text-base tracking-widest">
        / {title}
      </p>
      <p className="md:text-base text-sm leading-none">
        <Link to="/" className="flex items-center group leading-none">
          <span className=" duration-300 inline-block pt-1 mr-4 group-hover:mr-6">
            <BackToHomeIcon />
          </span>{" "}
          Home
        </Link>
      </p>
    </>
  )
  return (
    <section className="flex justify-between md:mb-5 mb-48 md:px-36 px-7 items-center">
      {title && titleBar}
    </section>
  )
}

PageHeaderTitle.propTypes = {
  title: PropTypes.string,
}

export default PageHeaderTitle
