import ctl from "@netlify/classnames-template-literals"
import React from "react"
import WaitlistArrow from "../../icons/waitlist-arrow"

const MoreAboutFourthCanvas = () => {
  const wrapper_style = ctl(`
    md:pl-10 
    lg:ml-64  
    px-7 
    mt-12
  `)

  const link_style = ctl(`
  inline-block 
  md:w-auto 
  w-full
  `)

  const button_style = ctl(`group 
  bg-black 
  text-white 
  px-8 
  md:pt-5 
  pt-3 
  flex 
  items-center 
  md:justify-start 
  justify-center  
  md:pb-6 
  pb-4 
  rounded-none 
  leading-none 
  md:w-auto 
  w-full`)

  const arrow_style = ctl(`transform 
  duration-300 
  inline-block 
  ml-5 
  group-hover:translate-x-7`)
  return (
    <section className={wrapper_style}>
      <a
        href="https://fourthcanvas.co"
        target="_blank"
        rel="noreferrer"
        className={link_style}
      >
        <button className={button_style} name="More about FourthCanvas">
          More about FourthCanvas
          <span className={arrow_style}>
            <WaitlistArrow />
          </span>
        </button>
      </a>
    </section>
  )
}

export default MoreAboutFourthCanvas
