import ctl from "@netlify/classnames-template-literals"
import React from "react"
import Container from "../../utils/container"

const AboutOrganizerDescription = () => {
  const h3_style = ctl(`
  font-semibold 
  md:text-3xl 
  text-2xl 
  md:pl-10 
  flex 
  my-12
  `)

  const black_box_style = ctl(`
  w-4 
  h-4 
  md:mt-4 
  mt-3 
  bg-black 
  flex-shrink-0 
  flex-grow-0 
  md:mr-8 
  mr-4
  `)
  return (
    <Container>
      <>
        <p>
          In partnership with visionary organizations,
          FourthCanvas—brand-centric design agency based in Lagos,
          Nigeria—designs compelling brands and products that stand out and
          succeed with people.
        </p>
        <h3 className={h3_style}>
          {/* Black box*/}
          <div className={black_box_style}></div>
          <span className="block ">
            We join forces with the bold and ambitous ones ready to take the
            long view, embrace distinction, and challenge the norm.
          </span>
        </h3>
      </>
    </Container>
  )
}

export default AboutOrganizerDescription
